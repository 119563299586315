import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import './servicesListSection.scss'

const ServicesListSection = ({ title, items }) => (
  <div className='services-list-container'>
    <div className='page-section page-container'>
      <h4 className='services-section-title'>{title}</h4>
      <div className='services-list-description'>
        {items.map((serviceItem) => (
          <div key={serviceItem.name}>
            <h3>{serviceItem.title}</h3>
            {serviceItem.description
              && (
                <Markdown options={{ html: true }}>
                  {serviceItem.description.description}
                </Markdown>
              )
            }
          </div>
        ))}
      </div>
    </div>
  </div>
)

ServicesListSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
}

export default ServicesListSection
