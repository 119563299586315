import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import './textSection.scss'
import Button from '../button'

const TextSection = ({
  imgSrc,
  imgAlt,
  description,
  addMarginTop,
  extraImages,
}) => {
  const hasExtraImages = extraImages && extraImages.length > 0
  return (
    <div className={`page-section page-container ${addMarginTop && 'default-margin-top'}`}>
      <div className='text-image-container'>
        <Markdown options={{ html: true }}>
          {description}
          <Button flashy to='/free-quote'>Get a Free Quote</Button>
        </Markdown>
        <div className={hasExtraImages ? 'multiple-image-column' : 'single-image-column'}>
          {imgSrc && <img className='service-content-image' src={imgSrc} alt={imgAlt} />}
          {hasExtraImages && (
            extraImages.map((image) => (
              <img className='service-content-image' src={image.file.url} alt={image.description} />
            ))
          )}
        </div>

      </div>
    </div>
  )
}


TextSection.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  description: PropTypes.string,
  addMarginTop: PropTypes.bool,
  extraImages: PropTypes.array,
}
export default TextSection
