import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { commonTags } from '../config/constants'
import ServiceHero from '../components/hero/serviceHero'
import VideoSection from '../components/videoSection'
import ServicesListSection from '../components/services/servicesListSection'
import TextSection from '../components/textSection'
import Layout from '../layouts'
import SliderSection from '../components/slider/SliderSection'

const ContactPage = ({ data }) => {
  const { contentfulPage } = data
  const {
    ogTitle, ogKeywords, ogDescription, ogImage, favIco, items,
  } = contentfulPage
  return (
    <Layout style={{ overflow: 'hidden' }}>
      <Helmet
        title={ogTitle}
        meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage)}
      >
        <link rel='icon' type='image/png' href={favIco.file.url}  />
      </Helmet>
      <div style={{ minHeight: '85.7vh' }}>
        {items.map((item) => {
          switch (item.internal.type) {
            case 'ContentfulHero': {
              return (
                <ServiceHero
                  key={item.title}
                  title={item.title}
                  imgSrc={item.image.file.url}
                  iconSrc={item.icon.file.url}
                  iconDescription={item.icon.description}
                />
              )
            }
            case 'ContentfulTextImageSection': {
              return (
                <div>
                  <div className='mobile-menu-placeholder' />
                  <TextSection
                    addMarginTop={items.length === 1}
                    key={item.name}
                    imgSrc={item.image.file.url}
                    imgAlt={item.image.description}
                    extraImages={item.extraImages}
                    description={item.description.description}
                  />
                </div>
              )
            }
            case 'ContentfulServicesList': return (
              <ServicesListSection
                key={item.name}
                title={item.title}
                items={item.items}
              />
            )
            case 'ContentfulVideoSection': return (
              <VideoSection
                key={item.name}
                title={item.title}
                youtubeId={item.youtubeId}
                video={item.video}
                coverImage={item.cover}
              />
            )
            case 'ContentfulSliderSection': return (
              <SliderSection
                key={item.title}
                items={item.items}
              />
            )

            default: return <div key='unknown'>.</div>
          }
        })}
        {/* <FreeQuoteBanner /> */}
      </div>
    </Layout>)
}

export const pageDataQuery = graphql`
  query servicePageDataTemplateQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      ogTitle
      ogDescription
      ogKeywords
      ogImage {
        description
        file {
          url
          contentType
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      favIco {
        file {
          url
        }
      }
      items {
        ...on ContentfulHero {
           internal {
            type
          }
          title
          image {
            file{
              url
            }
          }
          icon {
            description
            file {
              url
            }
          }
        }
        ...on ContentfulTextImageSection {
          internal {
            type
          }
          name
          image {
            description
            file{
              url
            }
          }
          extraImages {
            description
            file{
              url
            }
          }
          description {
            description
          }
        }
        ...on ContentfulServicesList {
          internal {
            type
          }
          name
          title
          items {
            name
            title
            description {
              description
            }
          }
        }
        ... on ContentfulSliderSection {
          id
          internal {
            type
          }
          items {
            fluid(maxHeight: 6280) {
              sizes
              src
              aspectRatio
              base64
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`

ContactPage.propTypes = {
  data: PropTypes.object,
}

export default ContactPage
