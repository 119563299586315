import React from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'

import './videoSection.scss'

const opts = {
  height: '330',
  width: '300',
}

const VideoSection = ({ title, youtubeId, video }) => (
  <div className='page-section page-container video-section'>
    {title && <h2>{title}</h2>}
    {youtubeId && (
      <YouTube
        videoId={youtubeId}
        opts={opts}
        containerClassName='videoContainer'
        className='video'
      />
    )}
    {video && (
      <video
        width="90%"
        src={`https:${video.file.url}#t=1`}
        title={video.title}
        controls
      />
    )}
  </div>
)

VideoSection.propTypes = {
  title: PropTypes.string,
  youtubeId: PropTypes.string,
  video: PropTypes.object,
}

export default VideoSection
